/* @tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: PlusJakartaSansRegular;
  src: url(/public/fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf);
}

@font-face {
  font-family: PlusJakartaSansSemibold;
  src: url(/public/assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBold.ttf);
} */

@font-face {
  font-family: 'PlusJakartaSans';
  font-style: normal;
  font-weight: 400;
  src: url('/public/assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSansSemibold';
  font-style: normal;
  font-weight: 600;
  src: url('/public/assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBold.ttf') format('truetype');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
